import React from "react"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Width,
  Link,
  Text,
  Instagram,
  Facebook,
  MentorFooter,
  MentorHeader,
} from "@/components/index"
import Img from "gatsby-image"

export default function Mentorship({ data }) {
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO
        title="Meet Sonora’s Mentors: Mark Whitfield"
        description="Sonora pairs world-class mentors with intermediate guitar students in an intensive 3-month program to help them find their breakthrough on guitar."
      />
      <MentorHeader
        name="Mark Whitfield"
        videoUrl="https://vimeo.com/498094199/57aba3edd1"
      />
      <Width pt="12.9rem" pb="7.2rem">
        <Box
          width={["100%", null, 10 / 12, null]}
          mx="auto"
          display="flex"
          flexDirection={["column", null, "row", null]}
        >
          <Box width={["100%", null, 4 / 12, null]} pr="2em">
            <Box borderRadius=".6rem" overflow="hidden">
              <Img
                alt={"curt"}
                fluid={data.markWhitfieldImage.childImageSharp.fluid}
              />
            </Box>
          </Box>
          <Box width={["100%", null, 8 / 12, null]}>
            <Text as="h1" fontWeight="bold" mb="1.2rem" mt={["2rem", null, "0rem", null]}>
              Mark Whitfield
            </Text>
            <Text level="1" mb="1.2rem">
              Jazz guitarist and performing artist
            </Text>
            <Box>
              <Link
                to="https://www.instagram.com/markwhitfieldtheguitarist"
                as="a"
              >
                <Instagram mr="1.2rem" />
              </Link>
              <Link
                to="https://www.facebook.com/officialmarkwhitfieldguitaristpage/"
                as="a"
              >
                <Facebook />
              </Link>
            </Box>
            <Text level="2" mt="3.6rem">
              Mark Whitfield is a Berklee-trained jazz guitarist who has
              collaborated with legendary artists including Dizzy Gillespie, Art
              Blakey, Quincy Jones, Ray Charles, Herbie Hancock, Carmen McRae,
              Gladys Knight, Burt Bacharach, Jimmy Smith, Clark Terry, Shirley
              Horn, Wynton Marsalis, Branford Marsalis, Joe Williams, Stanley
              Turrentine and his greatest teacher and mentor George Benson.
            </Text>
            <Text level="2">
              In 1990, the New York Times dubbed Whitfield “The Best Young
              Guitarist in the Business”. Later that year, Warner Bros. released
              his debut album The Marksman. The success of his debut release led
              to a recording career that has produced a total of 14 solo
              recordings and a myriad of collaborative efforts with some of the
              most important artists in recent years: Sting, Steven Tyler,
              D’Angelo, Mary J. Blige, John Mayer, Chaka Khan, Jill Scott, Diana
              Krall, Christian McBride, Chris Botti, Roy Hargrove and Nicholas
              Payton.
            </Text>
          </Box>
        </Box>
      </Width>
      <MentorFooter data={data} name="Mark Whitfield" />
    </Layout>
  )
}

/* Image and Other GQL Queries */
export const mentorshipQuery = graphql`
  query {
    markWhitfieldImage: file(
      relativePath: { eq: "markWhitfield-L.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    levelupImage: file(relativePath: { eq: "levelup.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    curriculumImage: file(relativePath: { eq: "curriculum.png" }) {
      childImageSharp {
        fluid(maxWidth: 570, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    communityImage: file(relativePath: { eq: "community.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    locationImage: file(relativePath: { eq: "location.png" }) {
      childImageSharp {
        fixed(width: 14, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
